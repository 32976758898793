import { Log } from '@lightningjs/sdk'
import { isEmpty } from 'lodash'
import * as LDClient from 'launchdarkly-js-client-sdk'

import AppConfigFactorySingleton from '../../config/AppConfigFactory'
import MParticleApi from '../../api/MParticleApi'
import { formatAppVersion, getBrandName, getProduct, getMpid, getPlatformName } from '../../helpers'
import version from '../../version'
import AuthenticationSingleton from '../../authentication/Authentication'
import { Subject } from 'rxjs'
import LaunchDarklyFeatureFlags from './LaunchDarklyFeatureFlags'

type FeatureFlags = Partial<Record<LaunchDarklyFeatureFlags, any>>

type FeatureFlagsEvent = Partial<
  Record<
    LaunchDarklyFeatureFlags,
    { current: any; previous: any } | { [key: string]: any } | string | boolean | number
  >
>

class LaunchDarkly {
  _featureFlags: FeatureFlags = {}
  _ldClient: LDClient.LDClient | null = null
  events = new Subject()

  set featureFlags(featureFlags: FeatureFlagsEvent) {
    const isObject = (val: any): val is { [key: string]: any } => typeof val !== 'string'
    for (const [key, value] of Object.entries(featureFlags)) {
      const current = isObject(value) ? value?.current || value : value // if the feature flag is from the 'change' event will come wrapped in current or previous
      featureFlags[key as LaunchDarklyFeatureFlags] = current?.rules?.[0] || current // temp-pass can come in a rules property
    }
    this._featureFlags = {
      ...this._featureFlags,
      ...featureFlags,
    }
  }

  /**
   * Get and save all feature flags
   */
  async _saveFeatureFlags(featureFlags: FeatureFlagsEvent = {}) {
    const featureFlagsLoaded = !isEmpty(this._featureFlags)
    this.featureFlags = featureFlags
    this.events.next(this._featureFlags)
    Log.info(
      `${LaunchDarkly.TAG} feature flags ${featureFlagsLoaded ? 'to update' : 'loaded'}`,
      featureFlags
    )
  }

  /**
   * Get user profile
   * @return User
   */
  async _getUser() {
    const mpAudiences = await MParticleApi.getAudiences()
    const isAuthenticated = AuthenticationSingleton.isAuthenticated()
    return {
      kind: 'user',
      key: getMpid(),
      'App Version': formatAppVersion(version),
      'Identity Auth': isAuthenticated,
      app: getBrandName(),
      Product: getProduct(),
      mpAudiences,
      Platform: getPlatformName(),
    }
  }

  /**
   * Initialize Launch Darkly SDK
   * @return Promise
   */
  async initialize() {
    const { clientSideId } = AppConfigFactorySingleton.config.launch_darkly

    // Get and identify LD user with mParticle.
    const user = await this._getUser()
    Log.info(`${LaunchDarkly.TAG} Version::`, (LDClient as any).version)
    Log.info(`${LaunchDarkly.TAG} initialize, user:`, user)

    // Initialize the LD client.
    this._ldClient = LDClient.initialize(clientSideId, user)
    this._ldClient.on('change', this._saveFeatureFlags.bind(this))
    await this._ldClient.waitUntilReady()

    // Wait for initial flags to be saved before resolving the promise
    await new Promise<void>((resolve) => {
      const saveFlags = async () => {
        await this._saveFeatureFlags(this._ldClient?.allFlags())
        resolve()
      }
      saveFlags()
    })

    Log.info(`${LaunchDarkly.TAG} initialization complete`)
  }

  getFeatureFlag(name: LaunchDarklyFeatureFlags) {
    const flag = this._featureFlags[name]
    return typeof flag === 'object' && 'current' in flag && 'previous' in flag ? flag.current : flag
  }

  getAllFlag() {
    return this._featureFlags
  }

  async updateUserAuthContext(auth: any) {
    const mpAudiences = await MParticleApi.getAudiences()
    this._ldClient?.identify({
      key: getMpid(),
      custom: {
        'Identity Auth': auth,
        'App Version': formatAppVersion(version),
        app: getBrandName(),
        Product: getProduct(),
        mpAudiences,
        Platform: getPlatformName(),
      },
    })
    Log.info(`${LaunchDarkly.TAG} context update, user:`, getMpid(), auth)
  }

  static TAG = 'Launch Darkly'
}

const LaunchDarklySingleton = new LaunchDarkly()
export default LaunchDarklySingleton
