import { Log, Router, Storage } from '@lightningjs/sdk'
import {
  Accessibility,
  Advertising,
  Capabilities,
  Device,
  Discovery,
  Lifecycle,
  Localization,
  Parameters,
  SecondScreen,
  SecureStorage,
} from '@firebolt-js/sdk'
import { uniqueId } from 'lodash'

import { StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js'

import { getDeviceId } from '../../DeviceIdUtils'
import BasePlatform, {
  PlatformSubscriptionType,
  SubscriptionWrapper,
  TV_PLATFORM_TAG,
} from '../base'

import { DebugControllerSingleton } from '../../../util/debug/DebugController'
import { APP_IDENTIFIER, ErrorType, IStageSettings, LEMONADE_PLATFORM } from '../types'
import { STORAGE_KEYS } from '../../../constants'
import { isXclass } from '../../../helpers'

// Event formatters
import { ComcastCCEvent, comcastFormatCC } from './comcastCC'
import { comcastFormatNavigation } from './comcastNavigation'
import { SupportedPlatforms } from '../../../graphql/generated/types'
import { Metrics } from '@firebolt-js/sdk'

type FireboltSubscribableModules =
  | typeof Accessibility
  | typeof Capabilities
  | typeof Device
  | typeof Discovery
  | typeof Lifecycle
  | typeof Localization
  | typeof SecondScreen
  | typeof SecureStorage

type GenericSubscriptionModule = { clear: () => void }

class ComcastSubscriptionWrapper extends SubscriptionWrapper {
  _generatedId: string
  id?: any
  module?: FireboltSubscribableModules | GenericSubscriptionModule
  stale: boolean
  constructor(
    generatedId: string,
    module?: FireboltSubscribableModules | GenericSubscriptionModule,
    id?: any
  ) {
    super()
    this._generatedId = generatedId
    this.module = module
    this.id = id
    this.stale = false
  }

  override unsubscribe() {
    if (!this.module) {
      this.stale = true
    }
    if (!this.stale && this.module?.clear) {
      this.stale = true
      return this.module?.clear(this.id)
    }
  }
}

export default class ComcastPlatform extends BasePlatform {
  _loadingScreenDismissed = false
  override _platformName = 'platco'
  override _lemonadePlatform = LEMONADE_PLATFORM.XCLASS
  override _bffPlatform = SupportedPlatforms.Platco
  override _streamingProtocol = StreamingProtocol.HLS
  override _appIdentifier = APP_IDENTIFIER.XCLASS
  override _subscriptions: ComcastSubscriptionWrapper[] = []
  override _deviceSdkConfig = { xfinity: { shouldUseFireboltApi: true } }
  _devicePartnerId = ''

  override get capabilities() {
    return {
      externalAppLinking: false,
      concurrentStreams: !isXclass(),
    }
  }

  override get devicePartnerId() {
    return this._devicePartnerId || 'comcast'
  }

  override async init(): Promise<void> {
    try {
      await this.handleParametersInit()
      await this.generateDeviceId()
      this._deviceType = await Device.type().then((device: string) =>
        device === 'smarttv' ? 'Xumo TV' : device
      )
      this._devicePartnerId = await Device.distributor()
      const advertising = await Advertising.advertisingId()
      if ('ifa' in advertising) this._advertiserId = advertising.ifa as string
      if ('ifa_type' in advertising) this._deviceAdvertisingIdType = advertising.ifa_type as string
      Log.debug(
        `Advertiser ID: ${this._advertiserId}, Device Advertising Type: ${this._deviceAdvertisingIdType}`
      )
      await this._syncDeviceUserOptOut()
    } catch (e) {
      this.reportError({
        type: ErrorType.OTHER,
        code: TV_PLATFORM_TAG,
        description: 'Something went wrong while injecting platform script',
        payload: e,
      })
    }
  }

  override async dismissLoadingScreen(): Promise<void> {
    if (this._loadingScreenDismissed) return Promise.resolve()
    await Lifecycle.ready()
    this._loadingScreenDismissed = true
  }

  override async generateDeviceId(): Promise<void> {
    try {
      const deviceId = getDeviceId()
      this.deviceId = deviceId ?? (await Device.id())
    } catch (e) {
      await super.generateDeviceId()
    }
  }

  /**
   * Sync Device User Opt Out settings
   * Store the last known value and only override app's tracking
   * setting if we detect there's been a change
   */
  _syncDeviceUserOptOut() {
    if (!isXclass()) {
      return
    }
    const LAST_KNOWN_DEVICE_UOO_SETTING = 'lastKnownDeviceUooSetting'
    const lastValue = Number(Storage.get(LAST_KNOWN_DEVICE_UOO_SETTING))
    const value = DebugControllerSingleton._getAdTrackingPreferenceFromUrl()
    Log.info(`Current device ad tracking preference: ${value}. Previous known value: ${lastValue}`)
    if (value !== null && lastValue !== value) {
      Storage.set(LAST_KNOWN_DEVICE_UOO_SETTING, value)
      Storage.set(STORAGE_KEYS.USER_OPT_OUT, value)
    }
  }

  override getAdvertiserDeviceType(): string {
    return 'platco'
  }

  /**
   * As Xclass platform doesn't use deeplinking we do nothing here
   */
  override handleDeepLink(): void {}

  override async getModelNumber() {
    try {
      return await Device.model()
    } catch (e) {
      return await super.getModelNumber()
    }
  }

  override subscribe = (
    evt: PlatformSubscriptionType,
    callback: (...params: any[]) => void
  ): SubscriptionWrapper => {
    // Filter stale events
    this._subscriptions = this._subscriptions.filter(({ stale }) => !stale)
    // Generate an unique ID which we can use to filter this subscription
    const generatedId = uniqueId()
    // Call Firebolt which will update subscription wrapper when it's ready
    this._handleAsyncSubscription(generatedId, evt, callback)
    // Meanwhile return the subscription wrapper
    const subscription = new ComcastSubscriptionWrapper(generatedId, undefined, undefined)
    this._subscriptions.push(subscription)
    return subscription
  }

  override getStageSettings(): IStageSettings {
    return {
      ...super.getStageSettings(),
      forceTxCanvasSource: true,
    }
  }

  private async _handleAsyncSubscription(
    generatedId: string,
    evt: PlatformSubscriptionType,
    callback: any
  ) {
    try {
      let subscriptionId, module
      switch (evt) {
        case PlatformSubscriptionType.VOICE:
          module = Accessibility
          subscriptionId = await Accessibility.listen('voiceGuidanceSettingsChanged', callback)
          if (isXclass()) {
            callback((await Accessibility.voiceGuidanceSettings()).enabled)
          }
          break
        case PlatformSubscriptionType.VOICE_CONTROL:
          module = Discovery
          subscriptionId = await Discovery.listen('navigateTo', (e) =>
            callback(comcastFormatNavigation(e))
          )
          break
        case PlatformSubscriptionType.CC:
          module = Accessibility
          await Accessibility.closedCaptionsSettings().then((data) => {
            callback(data.enabled, comcastFormatCC(data.styles as ComcastCCEvent))
          })
          subscriptionId = await Accessibility.listen(
            'closedCaptionsSettingsChanged',
            ({ enabled, styles }) => callback(enabled, comcastFormatCC(styles as ComcastCCEvent))
          )
          break
        case PlatformSubscriptionType.BACKGROUND: {
          module = Lifecycle
          // There's a Firebolt event called BACKGROUND, but per Comcast documentation we MUST use INACTIVE.
          // BACKGROUND isn't triggered in all cases (i.e.: player background, screen saver being activated...)
          subscriptionId = await Lifecycle.listen(Lifecycle.LifecycleState.INACTIVE, callback)
          break
        }
        case PlatformSubscriptionType.FOREGROUND:
          module = Lifecycle
          subscriptionId = await Lifecycle.listen(Lifecycle.LifecycleState.FOREGROUND, callback)
          break
        default:
          break
      }
      if (subscriptionId !== undefined && module) {
        const subscription = this._subscriptions.find(
          ({ _generatedId }) => _generatedId === generatedId
        )
        // We found the subscription wrapper, so update it with the final values
        if (subscription) {
          subscription.module = module
          subscription.id = subscriptionId
        } else {
          // The subscription wrapper was made at some time
          // but we unsubscribed before the async call ended
          // so we must clear the Firebolt event listener
          // and act nothing's happened
          module.clear(subscriptionId)
        }
      }
    } catch (e) {
      // We won't TVPlatform.reportError this one since it's an internal subscription
      Log.error(TV_PLATFORM_TAG, e)
    }
  }

  private async handleParametersInit(): Promise<void> {
    const initialParams = await Parameters.initialization()
    Log.info(`${TV_PLATFORM_TAG}-initialization`, initialParams)
  }

  override exit(): void {
    // super.exit()
    // Don't call super, because Firebolt doesn't actually close the app when calling Lifecycle.close
    // We still need the subscriptions for when we return to active state
    // Ref: https://docs.developer.comcast.com/docs/lifecycle-management [Closing your App section]
    Lifecycle.close(Lifecycle.CloseReason.USER_EXIT)
    Lifecycle.finished()
  }

  override exitToPeacock(): void {
    super.exitToPeacock()
  }

  override get deviceInfo() {
    return {
      primaryHardwareType: 'TV', //#restricted
      model: 'None', //#mandatory
      version: 'None',
      manufacturer: 'Hisense',
      vendor: 'Comcast',
      osName: 'Linux', //#mandatory, #restricted
      osFamily: 'Linux', // #restricted
      osVendor: 'None',
      osVersion: 'None',
      browserName: 'Symbian Browser', //#restricted
      browserVendor: 'Netscape', //#restricted
      browserVersion: 'None',
      userAgent: window.navigator.userAgent,
      displayWidth: window.innerWidth,
      displayHeight: window.innerHeight,
      displayPpi: 0,
      diagonalScreeenSize: 0,
      connectionIp: 'None',
      connectionPort: 0,
      connectionType: 'None',
      connectionSecure: false, //#restricted
      applicationId: 'None',
    }
  }

  override reportError(error: {
    type: ErrorType
    code?: string
    description: string
    payload?: any
  }) {
    super.reportError(error)
    Metrics.error(
      error.type,
      error.code || '',
      error.description,
      true,
      error.payload
        ? typeof error.payload === 'object'
          ? error.payload
          : { detail: error.payload }
        : undefined
    ).catch(() => {})
  }

  override historyBack(): void {
    Router.back()
  }
}
