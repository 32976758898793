export const isObject = (v: unknown) => {
  return typeof v === 'object' && v !== null
}

export const arrayMergeSplit = (array: string[], num: number) => {
  const ret = []
  if (num < 1) return array
  for (let i = 0; i < array.length; i += num) {
    const newSlice = array.slice(i, i + num).join('\n')
    ret.push(newSlice)
  }
  return ret
}
