import Announce from './Announce'

export default class BaseAnnounce extends Announce {
  private _utterance!: SpeechSynthesisUtterance

  async speak(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._utterance = new SpeechSynthesisUtterance(this._phrase)
      this._utterance.onend = (): void => resolve()
      this._utterance.onerror = (e): void => {
        // Unknown, cancel or interrupt error (ignore)
        if (e.error && !['canceled', 'interrupted'].includes(e.error)) reject(e)
      }
      window.speechSynthesis.speak(this._utterance)
      window.speechSynthesis.resume()
      const suppressPromise = () => {
        if (!window.speechSynthesis.speaking) {
          resolve();
          return;
        }
        window.setTimeout(suppressPromise, 200);
      }
      suppressPromise();
    })
  }
}
