import { Lightning, Utils } from '@lightningjs/sdk'
import { COLORS, FONT_FACE } from '../../../../../../constants'

export class SecondaryControlButton extends Lightning.Component {
  _bgColor = COLORS.dark

  static override _template() {
    return {
      Shader: {
        rect: true,
      },
      Label: {
        mountY: 0.5,
        text: {
          fontSize: 28,
          text: this.bindProp('label'),
          textColor: COLORS.white,
          fontFace: FONT_FACE.regular,
        },
      },
      Icon: { mountY: 0.5, x: 0, y: 0 },
    }
  }

  set icon(src: string) {
    if (!src) return
    this.tag('Icon').patch({
      src: Utils.asset(src),
      w: 28,
    })
  }

  override _init() {
    this.tag('Label').on('txLoaded', this._redraw.bind(this))
    this.tag('Icon').on('txLoaded', this._redraw.bind(this))
  }

  override _focus() {
    this._setColors(COLORS.white, COLORS.dark)
  }

  override _unfocus() {
    this._setColors(this._bgColor, COLORS.white)
  }

  set bgColor(color: COLORS) {
    if (color) this._bgColor = color
  }

  _redraw() {
    const xPadding = 20
    const gap = 20
    const iconWidth = this.tag('Icon').renderWidth
    const labelWidth = this.tag('Label').renderWidth
    const height = this.tag('Label').renderHeight + gap
    const middleY = height / 2
    const totalWidth = iconWidth + labelWidth + xPadding + gap
    this.patch({
      w: totalWidth,
      h: height,
      Shader: {
        w: totalWidth,
        h: height,
        shader: {
          type: Lightning.shaders.RoundedRectangle,
          radius: middleY,
          fillColor: this._bgColor,
        },
      },
      Label: {
        x: iconWidth + gap,
        y: middleY + 2,
        color: COLORS.white,
      },
      Icon: {
        x: gap / 2,
        y: middleY + 1,
      },
    })
  }

  _setColors(fillColor: COLORS, color: COLORS) {
    this.patch({
      Shader: {
        shader: {
          fillColor,
        },
      },
      Label: {
        color,
      },
      Icon: {
        color,
      },
    })
  }
}
