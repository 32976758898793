import { Log } from '@lightningjs/sdk'
import { Subscription } from 'rxjs'
import { get } from 'lodash'
//Player
import { PlayerStatus } from '../model/PlayerStatus'
//Events
import {
  BufferEndEvent,
  BufferStartEvent,
  DebugEvent,
  FatalErrorEvent,
  MutablePlayerEventStream,
  PlatcoPlayerEvents,
  PlayerStatusEvent,
  TimeChangeEvent,
  SeekStartEvent,
  SeekEndEvent,
} from '../model/event'

//Helpers and utils
import AppConfigFactorySingleton from '../../config/AppConfigFactory'
import PlayerStoreSingleton from '../../store/PlayerStore/PlayerStore'
import { isVod } from '../../components/player/helpers/metadata'
import {
  PlayerEventEmitterRegistry,
  PlayerEventSetup,
} from '../model/emitter/PlayerEventEmitterRegistry'
import TVPlatform from '../../lib/tv-platform'
import { CCStyleEvent } from '../../lib/tv-platform/base'
import { ErrorType } from '../../lib/tv-platform/types'
//constants
import { PROGRAMMING_TYPES } from '../../constants'
//Log const
const PLAYER_INTERFACE_TAG = 'Player Interface'

export enum PlayerState {
  INIT,
  LOADING,
  BUFFERING,
  RECOVERING,
  PLAYING,
  ERROR,
}

export enum PlayerMode {
  FULL = 'full',
  MINIMAL = 'minimal',
}

export type PlayerOptions = {
  left: number
  top: number
  width: number
  height: number
  zIndex: number
  domId: string
}

export class PlayerInterface {
  _playerDomEl?: HTMLElement | null
  _playerMode: PlayerMode
  _options?: Partial<PlayerOptions>
  _domId = 'video-player'

  // Player state
  _state = PlayerState.INIT
  _playerStatus = PlayerStatus.UNKNOWN

  // Player events
  // This is the class that maps player events to normalized Platco events
  _playerEventEmitterRegistry: PlayerEventEmitterRegistry | null
  // This is the internal event stream which we can subscribe from the view
  // and is populated with events sent from the emitters and the PlayerInterface
  _normalizedPlayerEvents: MutablePlayerEventStream | null = new MutablePlayerEventStream()
  // This is the internal RxJS subscription reference so we can cleanup at detach
  _subscription?: Subscription

  // Asset
  _id = 0
  _videoUrl = ''

  //Time
  _lastPosition = 0
  _savedSeekPosition = 0
  _startTime = -1

  // Errors
  _recoverMediaCount = 0

  // Interval
  _loadingInterval?: number | null
  _bufferingTimeout?: number | null

  static RECOVER_ERROR_TIMEOUT = 2000

  constructor(playerMode: PlayerMode, options?: Partial<PlayerOptions>) {
    this._options = options
    if (options?.domId) this._domId = options?.domId
    this._playerMode = playerMode
    this._playerEventEmitterRegistry = new PlayerEventEmitterRegistry(
      playerMode === PlayerMode.FULL ? PlayerEventSetup.ALL : PlayerEventSetup.MINIMAL
    )
    this._subscription = this.events?.subscribe(this._onEventReceived.bind(this))
  }

  get status() {
    return this._playerStatus
  }

  get normalizedPlayerEvents() {
    if (!this._normalizedPlayerEvents) {
      this._normalizedPlayerEvents = new MutablePlayerEventStream()
    }
    return this._normalizedPlayerEvents
  }

  get events() {
    return this.normalizedPlayerEvents.events()
  }

  get isLoading() {
    return this._state === PlayerState.LOADING
  }

  get isBuffering() {
    return this._state === PlayerState.BUFFERING
  }

  get isRecovering() {
    return this._state === PlayerState.RECOVERING
  }

  _onEventReceived(event: PlatcoPlayerEvents) {
    if (event instanceof PlayerStatusEvent) {
      this._playerStatus = event.status
      switch (event.status) {
        case PlayerStatus.READY:
          if (this._playerDomEl) {
            this._playerDomEl.style.visibility = 'visible'
          }
          break
        case PlayerStatus.LOADING:
          this._onLoading()
          break
        case PlayerStatus.PLAYING:
          this._state = PlayerState.PLAYING
          if (this._recoverMediaCount) this._recoverMediaCount = 0
          if (this._playerDomEl) {
            this._playerDomEl.style.visibility = 'visible'
          }
          break
        default:
          break
      }
    } else if (event instanceof ErrorEvent) {
      this._onError(event.error)
    } else if (event instanceof BufferStartEvent) {
      this._onBufferStart()
    } else if (event instanceof BufferEndEvent) {
      this._onBufferEnd()
    } else if (event instanceof DebugEvent) {
      Log.info(`${PLAYER_INTERFACE_TAG} debug event ${event.name}`, event.data)
    } else if (event instanceof TimeChangeEvent) {
      this._lastPosition = event.time
    } else if (event instanceof SeekStartEvent) {
      this._onSeekStart()
    } else if (event instanceof SeekEndEvent) {
      this._onSeekEnd()
    }
  }

  _detach() {
    this.events.complete()
    this._clearSession()
    this._subscription?.unsubscribe()
  }

  _clearSession() {
    if (this._playerDomEl) {
      this._playerDomEl.style.display = 'none'
    }
    this._playerEventEmitterRegistry?.detach()
    this._playerEventEmitterRegistry = null
    if (this._bufferingTimeout) clearTimeout(this._bufferingTimeout)
    if (this._loadingInterval) clearInterval(this._loadingInterval)
  }

  clearPreviousSession() {
    this._clearSession()
  }

  close() {
    this._detach()
  }

  isPlayingAd() {
    return false
  }

  _onLoading() {
    if (this.isLoading) return

    this._state = PlayerState.LOADING
    const maxRetry = get(AppConfigFactorySingleton.config, 'hls_player.recoverMediaMaxRetry', 3)
    if (this._loadingInterval) {
      clearInterval(this._loadingInterval)
    }
    this._loadingInterval = window.setInterval(
      () => {
        if (this.isLoading && this._recoverMediaCount < maxRetry) {
          // Attempt to recover media.
          this._recoverMediaCount += 1
          Log.error(`${PLAYER_INTERFACE_TAG} recover media count ${this._recoverMediaCount}`)
          this._onRecoverMediaError()
        } else {
          // Playback resumed or unable to recover media.
          if (this._loadingInterval) clearInterval(this._loadingInterval)
          if (this._recoverMediaCount === maxRetry) {
            const description = `${PLAYER_INTERFACE_TAG} unable to recover media, redirect to error page.`
            TVPlatform.reportError({
              type: ErrorType.MEDIA,
              code: PLAYER_INTERFACE_TAG,
              description,
            })
            this._normalizedPlayerEvents?.publish(
              new FatalErrorEvent({ fatal: true, description, code: '404' })
            )
            this._recoverMediaCount = 0
          }
        }
      },
      get(
        AppConfigFactorySingleton.config,
        'hls_player.recoverErrorTimeout',
        PlayerInterface.RECOVER_ERROR_TIMEOUT
      )
    )
  }

  _onBufferEnd() {
    Log.info(`${PLAYER_INTERFACE_TAG} buffer end`)
    this._state = PlayerState.PLAYING
    if (this._bufferingTimeout) clearTimeout(this._bufferingTimeout)
  }

  _onSeekEnd() {
    Log.info(`${PLAYER_INTERFACE_TAG} seek end`)
    this._onBufferEnd()
  }

  _onSeekStart() {
    Log.info(`${PLAYER_INTERFACE_TAG} seek end`)
    this._onBufferStart()
  }

  _onBufferStart() {
    if (this.isBuffering) return
    Log.info(`${PLAYER_INTERFACE_TAG} buffer start`)
    this._state = PlayerState.BUFFERING
    if (this._bufferingTimeout) clearTimeout(this._bufferingTimeout)
    this._bufferingTimeout = window.setTimeout(
      () => {
        Log.error(`${PLAYER_INTERFACE_TAG} buffering, attempt to recover media`)
        this._onRecoverMediaError()
        this._state = PlayerState.ERROR
      },
      get(AppConfigFactorySingleton.config, 'hls_player.bufferingTimeout', 8000)
    )
  }

  getSafeSeekPosition(positionInMilliseconds: number): number {
    Log.warn(`${PLAYER_INTERFACE_TAG} get safe seek position called on interface`)
    const programmingType = PlayerStoreSingleton.program?.programmingType

    if (programmingType === PROGRAMMING_TYPES.FER || isVod(programmingType))
      return positionInMilliseconds
    return -1
  }

  setVideoSize(left: number, top: number, width: number, height: number) {}

  setVisibility(visible: boolean) {}

  _onRecoverMediaError() {}

  // These need to be overridden
  isPlaying() {
    return false
  }

  _onError = (error = {}) => {}

  seek(positionInMilliseconds: any) {}

  play() {}

  pause() {}

  seekToLiveEdge() {}

  get version() {
    return ''
  }

  setCCStyle(options: CCStyleEvent) {}

  setMute(mute: boolean): void {}

  setVolume(volume: number): void {}
}
