import { Language } from '@lightningjs/sdk'

import { ROUTE } from '../constants'
import { useRequest, OKResponse } from '../lib/useRequest'
import { globalNavigationRequest } from '../api/requests'
import Variables from '../graphql/Variables'

class GlobalNavigation {
  _consumer: any
  _items: any
  _initiallySelected = 0

  consumer(consumer: any) {
    this._consumer = consumer
  }

  get items() {
    return this._items
  }

  set items(v: any) {
    this._items = v
    this._consumer.items = this._items
  }

  set initiallySelected(v: any) {
    this._initiallySelected = v
    this._consumer.itemsBar.initiallySelected = v
  }

  async load() {
    const fetchNavigationData = async () => {
      const data = await useRequest({
        query: globalNavigationRequest,
        variables: Variables.getGlobalNavigationVariables(),
        reject: [(data) => !data || !data.items.length],
      }).fetch()

      const index = data?.initiallySelected
      const shouldUpdateIndex = typeof index === 'number' && index !== this._initiallySelected
      if (shouldUpdateIndex) {
        this.initiallySelected = index
      }

      this._bffNavigationAdapter(data)
    }

    try {
      await fetchNavigationData()
    } catch (error) {
      try {
        // Retry once on failure
        await fetchNavigationData()
      } catch (retryError) {
        this._setDefaultNavItems()
      }
    }
  }

  _setDefaultNavItems = () => {
    this.items = [
      {
        label: Language.translate('home'),
        route: ROUTE.home,
        ariaLabel: `${Language.translate('home')}, Page`,
      },
      {
        label: Language.translate('shows_movies'),
        route: ROUTE.shows,
        ariaLabel: `${Language.translate('shows_movies')}, Page`,
      },
      {
        label: Language.translate('sports'),
        route: ROUTE.sports,
        ariaLabel: `${Language.translate('sports')}, Page`,
      },
      {
        label: Language.translate('live'),
        route: ROUTE.live,
        ariaLabel: `${Language.translate('live')}, Page`,
      },
      {
        label: Language.translate('networks'),
        route: ROUTE.networks,
        ariaLabel: `${Language.translate('networks')}, Page`,
      },
      {
        icon: 'search.png',
        active: 'search-active.png',
        route: ROUTE.search,
        ariaLabel: `${Language.translate('search')}, Page`,
      },
    ]
  }

  _bffNavigationAdapter(data: OKResponse<typeof globalNavigationRequest>) {
    if (!data?.items?.length) return

    this.items = data.items.reduce<
      Array<{
        icon: string
        active: string
        focused: string
        route: string
        label: string
        ariaLabel: string
      }>
    >((acc, response: any) => {
      if (!response?.data) return acc

      const { title, defaultLogo, focusedLogo, selectedLogo, destination, ariaLabel } =
        response.data

      if (destination?.toLowerCase() === ROUTE.olympics) return acc

      acc.push({
        icon: defaultLogo,
        active: selectedLogo,
        focused: focusedLogo,
        route: destination,
        label: title,
        ariaLabel,
      })

      return acc
    }, [])
  }
}

const GlobalNavigationSingleton = new GlobalNavigation()

export default GlobalNavigationSingleton
