import Announce from './Announces/Announce'
import TVPlatform from '../tv-platform'
import { AnnouncementType } from './types'
import { PLATFORM_TYPES, splitTextToFitLength } from '../../helpers'

class SpeechController {
  private _active = false
  private _voiceOutDisabled = false
  private _announces: Announce[] = []
  private _tts = TVPlatform.tts()

  get active(): boolean {
    return this._active
  }

  get voiceOutDisabled(): boolean {
    return this._voiceOutDisabled
  }

  append(toSpeak: AnnouncementType[], notification = false): void {
    toSpeak.forEach((announce) => {
      const seriesArray =
        (this._normalize(Array.isArray(announce) ? announce : [announce]))
          .filter((item) => item.length)
      if (!seriesArray.length) return
      for (const phrase of seriesArray) {
        this._announces.push(this._tts.speak(phrase, notification))
      }
    })
  }

  cancel(): void {
    this._announces = []
    this._tts.cancel()
    this._active = false
    this._voiceOutDisabled = false
  }

  async speak(series: AnnouncementType[], notification = false): Promise<void> {
    this._active = true
    this.append(series, notification)
    await this._speakSeries()
  }

  private async _speakSeries(): Promise<void> {
    while (this._announces.length) {
      const series = this._announces.shift()
      if (!series) continue
      this._voiceOutDisabled = series.notification
      await series.speak()
    }
    this._voiceOutDisabled = false
    this._active = false
  }

  private _normalize(phrasesArray: Array<AnnouncementType>): Array<string> {
    let normalizedPhrasesArray: string[] = []
    for (const string of phrasesArray) {
      if (Array.isArray(string)) {
        normalizedPhrasesArray.push(this._normalize(string).join(' '))
      } else {
        const phrase = typeof string === 'function' ? string() : string
        const result = DEVICE_PLATFORM === PLATFORM_TYPES.XCLASS && phrase.length > 200
          ? splitTextToFitLength(phrase)
          : phrase
        if (Array.isArray(result)) {
          normalizedPhrasesArray.push(...result)
        } else {
          normalizedPhrasesArray.push(result)
        }
      }
    }
    return normalizedPhrasesArray
  }
}

export default new SpeechController()
