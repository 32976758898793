// @ts-expect-error TS(7016): Could not find a declaration file for module 'js-c... Remove this comment to see the full error message
import Cookies from 'js-cookie'
import { Storage } from '@lightningjs/sdk'

import { FavoriteInteractionFactorySingleton } from '../graphql/modifiers/FavoriteInteractionFactory'
import { WatchProgressFactorySingleton } from '../graphql/modifiers/WatchProgressFactory'

import { getUserProfile } from '../api/Identity'
import AppConfigFactory from '../config/AppConfigFactory'
import AuthenticationSingleton from '../authentication/Authentication'

import { STORAGE_KEYS, UNAUNTHENTICATED } from '../constants'
import MvpdData from '../api/models/MvpdData'

export const checkGetMvpd = () =>
  new Promise((res, rej) => {
    AuthenticationSingleton.checkStatus()
      .then(() => res(getMvpd()))
      .catch((err) => rej(err))
  })

export const getMvpdServiceBaseUrl = () => {
  return AppConfigFactory.get().config.mvpdService.baseUrl
}

export const getMvpdLogo = (mvpd: any | null = null) => {
  if (!mvpd) return
  return `${getMvpdServiceBaseUrl()}/${mvpd?.mvpdProviderData?.apploggedInImage}`
}

export const getMvpdLogoSrc = (src: any) => {
  if (!src) return
  return `${getMvpdServiceBaseUrl()}/${src}`
}

export const getProfileValue = () => (getUserProfile() ? 'Free' : 'Unauthenticated')

export const getMvpd = () =>
  AuthenticationSingleton.isAuthenticated()
    ? AuthenticationSingleton.getMvpdData()
    : UNAUNTHENTICATED

export const getMvpdValue = () => (getMvpd() as MvpdData)?.mvpdDisplayName || 'Unauthenticated'

export const getCookieNamesByPrefix = (prefix: any) => {
  const cookies = document.cookie.split(';')
  return cookies
    .filter((ck) => ck && ck.indexOf(prefix) > -1)
    .map((ck) => ck?.split('=')[0]?.replace(/\s/g, ''))
}

export const getCookie = (name: any) => Cookies.get(name)

export const removeCookie = (name: any, params: any) => {
  if (params) Cookies.remove(name, params)
  else Cookies.remove(name)
}

/**
 * Check if user has a profile or mvpd linked
 * hasn't saved a show to favorites or watched content
 * @return {boolean} is user anonymous
 */
export const getIsDayZero = () => {
  const isMVPDLinked = AuthenticationSingleton.isAuthenticated()
  const hasWatchedContent = WatchProgressFactorySingleton.get().hasWatchedContent
  const hasSavedFavorite = FavoriteInteractionFactorySingleton.get().hasSavedFavorite
  const wasDayZeroStored = Storage.get(STORAGE_KEYS.IS_NOT_DAY_ZERO)
  return (
    !(isMVPDLinked || isNBCProfileLinked()) &&
    !hasWatchedContent &&
    !hasSavedFavorite &&
    !wasDayZeroStored
  )
}

export const isNBCProfileLinked = () => {
  return !!getUserProfile()
}

export interface Resource {
  id: string
  authorized: boolean
}

export interface PreAuthData {
  resources: Array<Resource>
}

export interface ResourceObject {
  [key: string]: boolean
}

export interface AdobePathError {
  body: string
  title: string
  useAdobeDesc?: number
}

export interface AdobePathErrorMappings {
  [key: string]: AdobePathError
}

export interface MvpdProviderData {
  activationloggedInImage: string
  activationloggedInImage_2x: string
  activationpickerImage: string
  activationpickerImage_2x: string
  adobePassErrorMappings: AdobePathErrorMappings
  advertisingKey: string
  apploggedInImage: string
  apploggedInImage_2x: string
  apppickerImage: string
  apppickerImage_2x: string
  concurrencyFlow: number
  mvpd: string
  mvpd_display_name: string
  mvpd_url: string
  tier: number
}

export interface MvpdMetadata {
  data: {
    encryptedZip: string
    hba_status: string
    mvpd: string
    upstreamUserID: string
    userID: string
    zip?: string
  }
  encrypted: Array<any>
  state: string
  updated: number
}

export const transformResources = (resources: Resource[]): ResourceObject => {
  const resourceObject: ResourceObject = {}
  resources.forEach((resource) => {
    resourceObject[resource.id] = resource.authorized
  })
  return resourceObject
}
