export const isNumberLike = (input: unknown): boolean => {
  // Handle arrays and objects
  if (typeof input === 'object') {
    return false
  }

  // Handle empty strings and whitespace
  if (typeof input === 'string' && input.trim() === '') {
    return false
  }

  // Then check if it's a valid number or can be converted to one
  const num = Number(input)
  return !Number.isNaN(num) && input !== null && input !== undefined
}

export const looseStringCompare = (...arr: (string | null | undefined)[]) => {
  for (let i = 0, previous: string | null | undefined; i < arr.length; i++) {
    const current = arr[i]
    const equal = previous?.toLowerCase() === current?.toLowerCase()
    if (i > 0 && !equal) return false
    previous = current
  }
  return true
}
