import { Lightning, Registry } from '@lightningjs/sdk'

import SLEStrategy from './strategies/SLEStrategy'
import TempPassCTA from './TempPassCTA'

import { EVENTS } from '../../../lib/analytics/types'
import { sendMetric } from '../../../lib/analytics/Analytics'
import { TEMP_PASS_REDIRECT } from '../../../pages/Player/BasePlayer'
import { PlayerControlsStates, COLORS } from '../../../constants'
import { KeyMap } from '../../../lib/KeyMap'
import IPlayerControlsStrategy from './strategies/IPlayerControlsStrategy'
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore'
import { TimeChangeEvent } from '../../../player/model/event'
import { ProgressHookEvent } from './hooks/useProgress'
import { activateTempPassRedirect } from '../../../pages/Player/StreamLoader/actions/redirect'
import { useRouterBackDisabled } from '../../../widgets/Modals/activation/hooks/useRouterBackDisabled'
import { TitleContainerStateFactory } from './states/TitleContainer'
import { TimerDesign } from './components/Timer'
import { HeadingV2 } from './components/HeadingV2'
import { SecondaryControlButtons } from './components/HeadingV2/SecondaryControls'
import SeekablePlayerControlsV2 from './SeekablePlayerControlsV2'

const TIMEOUT_HIDE_TEMPPASS_TIMER = 5000

export default class SLEPlayerControlsV2 extends SeekablePlayerControlsV2 {
  _tempPassControlsOverlay: any
  _heading: HeadingV2

  override _strategy: IPlayerControlsStrategy = new SLEStrategy(this)
  override _keyMap = KeyMap(this, [
    [
      [PlayerControlsStates.QOSPanel],
      [PlayerControlsStates.TempPass],
      [PlayerControlsStates.QOSPanel],
    ],
    [[], PlayerControlsStates.AdControls, []],
    [[], PlayerControlsStates.TitleContainer, []],
    [[], PlayerControlsStates.PlayOrPause, []],
  ])

  static override PROGRESSBAR_TOTAL_WIDTH = 1400
  static override _template() {
    return super._template({
      PlayerControls: {
        x: 70,
        SettingsContainer: undefined,
        TitleContainer: {
          type: HeadingV2,
          buttons: [
            SecondaryControlButtons.BackToLiveButton,
            SecondaryControlButtons.LiveGuideButton,
            SecondaryControlButtons.MediaOptions,
          ],
          HeadingContainer: undefined,
          SecondaryTitle: undefined,
          Description: undefined,
        },
        ControlsWrapper: {
          y: 960,
          x: 28,
          PlayOrPause: {
            y: 8,
          },
          Progress: {
            y: 10,
            x: 70,
            CurrentBarBg: {
              w: 0,
              h: 8,
              y: 7,
              rect: true,
              alpha: 0.5,
              color: COLORS.red,
            },
            CurrentBar: {
              color: COLORS.red,
            },
            SeekBar: {
              zIndex: 10,
              BigCircle: undefined,
              SmallCircle: undefined,
              Shader: {
                rect: true,
                w: 24,
                h: 24,
                x: 0,
                y: 0,
                mountX: 0.5,
                shader: {
                  type: Lightning.shaders.RoundedRectangle,
                  strokeColor: COLORS.white,
                  fillColor: COLORS.red,
                  stroke: 3,
                  radius: 12,
                },
              },
            },
          },
          Timer: {
            x: 0,
            y: 0,
            w: 1740,
            design: TimerDesign.V2,
          },
        },
      },
    })
  }
  override _init() {
    this._heading = this.tag('TitleContainer') // calling this first so it gets initialized before controls setup
    super._init()
  }

  override get seekingAllowed() {
    return PlayerStoreSingleton.lemonade?.pvrControls || false
  }

  setTitle(text: string) {
    this._heading?.setTitle(text)
  }

  setSecondaryTitle(text: string) {
    this._heading?.setSecondaryTitle(text)
  }

  setDescription(text: string) {
    this._heading.setDescription(text)
  }

  override getTitleContainerY() {
    return (this.constructor as any).TITLE_CONTAINER_Y
  }

  patchTempPassTemplate() {
    this.patch({
      TempPass: {
        type: TempPassCTA,
        alpha: 1,
      },
    })
    this._tempPassControlsOverlay = this.tag('TempPass')
    this.tempPassCtaClicked = false
  }

  createTimeOut() {
    Registry.setTimeout(() => {
      this.fireAncestors('$hideTempPassTimer')
    }, TIMEOUT_HIDE_TEMPPASS_TIMER)
  }

  updateTempPassCountdown(secondsLeft: any) {
    if (secondsLeft <= 30 && this._getState() !== PlayerControlsStates.TempPass) {
      this.fireAncestors('$showTempPassTimer', { pin: true })
    }
    this._tempPassControlsOverlay.update(secondsLeft)
  }

  override _updateProgress([currentTime, duration, seekableRange]: ProgressHookEvent) {
    if (!duration) return
    const totalWidth = this.tag('TotalBar').w
    const lessThan1MinLeft =
      this._getState() !== PlayerControlsStates.Seeking && duration - currentTime <= 60
    const position = lessThan1MinLeft ? totalWidth : (currentTime * totalWidth) / duration
    const seekEnd =
      seekableRange && !lessThan1MinLeft ? (seekableRange?.[1] * totalWidth) / duration : position
    this.tag('Progress').patch({
      CurrentBarBg: { w: seekEnd },
      CurrentBar: { w: position },
      SeekBar: { x: position },
    })
  }

  override _playerSubscriptionHandler(event: any) {
    super._playerSubscriptionHandler(event)
    if (event instanceof TimeChangeEvent) {
      //toggle "back to live" button based on isAtLiveEdge property from player
      this._heading.toggle(SecondaryControlButtons.BackToLiveButton, !event._isAtLiveEdge)
    }
  }

  override _setLogo(src?: string) {
    this._heading?.setLogo(src)
  }

  static override _states() {
    return [
      ...super._states(),
      class TempPass extends this {
        override _handleEnter() {
          sendMetric(EVENTS.CLICK, {
            name: 'TempPass - Link TV Provider',
          })

          this.tempPassCtaClicked = true
          useRouterBackDisabled().set(true)
          activateTempPassRedirect(TEMP_PASS_REDIRECT.TEMP_PASS_MVPD)
        }
        override _getFocused() {
          return this.tag('TempPass') || this
        }
      },
      TitleContainerStateFactory(this),
    ]
  }
}
