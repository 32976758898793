export class StopWatch {
  _end: any
  _start: any
  constructor() {
    this._start = null
    this._end = null
  }

  start = () => {
    this._start = new Date().getTime()
  }

  stop = () => {
    this._end = new Date().getTime()
  }

  duration = () => {
    if (this._start && this._end) {
      return this._end - this._start
    } else {
      throw new Error('You must start and stop the StopWatch!')
    }
  }
}
