import BasePlatform, {
  PlatformSubscriptionType,
  SubscriptionWrapper,
  TV_PLATFORM_TAG,
} from '../base'
import { APP_IDENTIFIER, ErrorType, LEMONADE_PLATFORM } from '../types'
import { StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js'
import { SupportedPlatforms } from '../../../graphql/generated/types'
import { KeyCodes, Keys, USER_OPT_OUT_PREFERENCE } from '../../../constants'
import KeplerAnnounce from '../../tts/Announces/KeplerAnnounce'
import BaseAnnounce from '../../tts/Announces/BaseAnnounce'
import { Registry } from '@lightningjs/sdk'
import {
  KeplerMessage,
  KeplerConfig,
  INCOMING_KEPLER_MESSAGE_TYPE,
  OUTGOING_KEPLER_MESSAGE_TYPE,
} from './types'
import AppConfigFactorySingleton from '../../../config/AppConfigFactory'

class KeplerSubscriptionWrapper extends SubscriptionWrapper {
  _unsubscribeCb: any
  stale = false

  constructor(unsubscribeCb: any) {
    super()
    this._unsubscribeCb = unsubscribeCb
  }

  override unsubscribe(): void {
    this.stale = true
    this._unsubscribeCb?.()
  }
}

export default class KeplerPlatform extends BasePlatform {
  override _platformName = 'Kepler'
  override _lemonadePlatform = LEMONADE_PLATFORM.KEPLER
  override _bffPlatform = SupportedPlatforms.FireTvLightning
  override _streamingProtocol = StreamingProtocol.DASH
  override _appIdentifier = APP_IDENTIFIER.KEPLER
  override _subscriptions: KeplerSubscriptionWrapper[]
  _keplerConfig: KeplerConfig

  override get devicePartnerId() {
    return AppConfigFactorySingleton.config?.identity?.device_type || 'FireTv'
  }

  override async init(): Promise<void> {
    this._deviceType = 'Fire TV'
    await this.generateDeviceId()
    //listen for the initial config from kepler
    Registry.addEventListener(window, 'message', ({ data }: MessageEvent) => {
      switch (data.type as INCOMING_KEPLER_MESSAGE_TYPE) {
        case INCOMING_KEPLER_MESSAGE_TYPE.SET_CONFIG:
          this._keplerConfig = data.payload
          break
        case INCOMING_KEPLER_MESSAGE_TYPE.DEVICE_INFO:
          window?.ReactNativeWebView?.setDeviceInfoCallback?.(data.payload)
          break
        case INCOMING_KEPLER_MESSAGE_TYPE.DEEP_LINK:
          window?.ReactNativeWebView?.setDeeplinkDataCallback?.(data.payload)
          break
        default:
          break
      }
    })
    this._sendMessageToShell(OUTGOING_KEPLER_MESSAGE_TYPE.LOADED)
  }

  override subscribe = (
    evt: PlatformSubscriptionType,
    callback: any
  ): KeplerSubscriptionWrapper | undefined => {
    // Filter stale events
    this._subscriptions = this._subscriptions.filter(({ stale }) => !stale)
    if (evt === PlatformSubscriptionType.VOICE) {
      if (this._ttsEnabled()) {
        const handleMessage = (data: any) => callback(true)
        Registry.addEventListener(window, 'message', handleMessage)
      }
    }
    return undefined
  }

  _sendMessageToShell = (messageType: string) => {
    if (window?.ReactNativeWebView) {
      const message: KeplerMessage<string> = { type: messageType }
      window.ReactNativeWebView.postMessage(JSON.stringify(message))
    }
  }

  /**
   * exit the application
   * @returns {void}
   */
  override exit() {
    try {
      this._sendMessageToShell(OUTGOING_KEPLER_MESSAGE_TYPE.EXIT)
      super.exit()
    } catch (e) {
      this.reportError({
        type: ErrorType.OTHER,
        code: TV_PLATFORM_TAG,
        description: 'Error on Kepler exit',
        payload: e,
      })
    }
  }

  /**
   * firetv specific key bindings
   * @returns {boolean}
   */
  override getPlatformKeyMapping() {
    return {
      ...this.getAtoZAndNumberKeyMapping(true),
      ...super.getPlatformKeyMapping(),
      [KeyCodes.kepler.Back]: Keys.BACKSPACE,
    }
  }

  override tts() {
    return {
      speak(toSpeak: string, notification = false) {
        return window.ReactNativeWebView
          ? new KeplerAnnounce(toSpeak, notification)
          : new BaseAnnounce(toSpeak, notification)
      },
      cancel() {
        // todo: cancel method for kepler
      },
    }
  }

  _ttsEnabled() {
    //todo: enact tts enabled when available in webview
    return false
  }

  /**
   * Advertiser Id is set on the initial config passed from device
   * @returns {string}
   * @private
   */
  override getAdvertiserId(): string {
    return this._keplerConfig?.adTrackingEnabled
      ? this._keplerConfig.advertiserId
      : super.getAdvertiserId()
  }

  /**
   * User opt out value for ads  1 = false (disallow), 0 = true (allow)
   * Evaluate the storage (set through privacy settings)
   * The debug override (set in debug menu)
   * Additionally, if user does not have an advertiser id, we should disallow
   * advertiserId will come back as a blank string if not enabled from XBox
   *
   * @returns {boolean}
   */
  override getUserOptOut() {
    try {
      const storageOptOut = this.getStorageBasedOptOut()
      return !this._keplerConfig?.adTrackingEnabled ||
        storageOptOut === USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
        ? USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
        : USER_OPT_OUT_PREFERENCE.ALLOW_SALE
    } catch (e) {
      return super.getUserOptOut()
    }
  }
}

declare global {
  interface Window {
    ReactNativeWebView?: {
      setDeviceInfoCallback?(payload: any): void
      setDeeplinkDataCallback?(payload: any): void
      postMessage(message: string): void
    }
  }
}
